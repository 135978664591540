<template>
  <div class="home">
    <t_header :isUserInfo="isUserInfo" @setTodos="getTodos" :isSort="true"></t_header>
     <!-- :isSort="isSort"  -->
    <div class="home_banner">
        <el-carousel height="380px" :interval="interval" indicator-position="none" >
            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                <img :src="item.imgPath" @click="toimgdetail(item)" v-if="item.type==1">
                <img :src="item.imgPath" v-if="item.type==2">
            </el-carousel-item>
        </el-carousel>
    </div>
    <div class="home_tips" v-if="isShowModule==true">
        <div v-for="(item,index) in moduleList" :key="index">
            <p>
                <img src="@/assets/home/bigClassify_1.png" alt="" v-if="index==0">
                <img src="@/assets/home/bigClassify_3.png" alt="" v-if="index==1">
                <img src="@/assets/home/bigClassify_4.png" alt="" v-if="index==2">
                <img src="@/assets/home/bigClassify_2.png" alt="" v-if="index==3">
            </p>
            <p>{{item.businessType}}</p>
            <p class="home_tips_hover">
                <span v-for="(item,key) in moduleList[index].itemList" :key="key" @click="goskip(item)">{{item.name}} <span style="margin:0 10px;">|</span></span>
            </p>
        </div>
        
    </div>
    <div class="home_tips" v-else>
        <div class="">
            <p>
                <img src="@/assets/home/bigClassify_1.png" alt="" >
            </p>
            <p>会计考证课</p>
        </div>
        <div class="">
            <p>
                <img src="@/assets/home/bigClassify_2.png" alt="" >
            </p>
            <p>会计实操课</p>
        </div>
        <div class="">
            <p>
                
                <img src="@/assets/home/bigClassify_3.png" alt="" >
            </p>
            <p>财经金融课</p>
        </div>
        <!-- <div class="">
            <p>
                <img src="@/assets/home/bigClassify_4.png" alt="" >
            </p>
            <p>考试资讯</p>
        </div> -->
    </div>
    <div class="home_title" v-if="freeLiveList.length>0">
        <p>免费直播</p>
        <p @click="more_zhibo()">更多<img src="@/assets/right_arrow.png"></p>
    </div>
    <div class="home_msg" v-if="freeLiveList.length>0">
        <div class="swiper-container" id="a1">
            <div class="home_msg_live swiper-wrapper" ref="swiper">
                <div v-for="(item,index) in freeLiveList" @click="toLiveDetail(item)" :key="index" class="home_msg_live_item swiper-slide">
                    <div class="home_msg_live_item_left">
                        <img :src="item.teacherHeaderPath">
                        <p class="home_msg_live_item_left_name">{{item.teacherName}}</p>
                    </div>
                    <div class="home_msg_live_item_right">
                        <p class="home_msg_live_item_right_content">
                            <span>{{item.liveTIme}}</span>
                            <span>{{item.liveName}}</span>
                        </p>
                        <p class="home_msg_live_item_right_icon" >
                            <img src="@/assets/home/live_icon.png" alt="">
                            <span v-if="item.type==0">正在直播</span>
                            <span v-if="item.type==1">待直播</span>
                        </p>
                    </div>
                    <!--  -->
                </div>
            </div>
        </div>
        <div class="inl btnl swiper-button-prev" id="aa_right"></div>
        <div class="inl btnr swiper-button-next" id="aa_left"></div>
    </div>
    <div class="home_title" v-if="freeCourseList.length>0">
        <p>免费课程</p>
        <p @click="morecourse(2)">更多<img src="@/assets/right_arrow.png"></p>
    </div>
    <div class="home_msg" v-if="freeCourseList.length>0">
        <div class="swiper-container" id="a2"> 
            <div class="swiper-wrapper" ref="swiper">
                <div class="swiper-slide" v-for="(item,index) in freeCourseList" @click="todetail(item)" :key="index">
                    <div class="home_msg_right">
                        <img :src="item.coverPath">
                        <div class="home_msg_right_title">{{item.packageName}}</div>
                        <div v-if="item.isFree != 0">
                            <span class="color_red">¥{{item.discountPrice}}</span>
                            <span class="color_ccc">¥{{item.price}}</span>
                        </div>
                        <div v-if="item.isFree == 0">
                            <span class="color_blue">免费</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="inl btnl swiper-button-prev" id="bb_right"></div>
        <div class="inl btnr swiper-button-next" id="bb_left"></div>
    </div>
    <!-- <div v-if="transitionList.length > 0">
        <img class="home_advertisement" :src="transitionList[0].picUrl" @click="goHtml(transitionList[0].content,transitionList[0].is_jump,transitionList[0])">
    </div> -->
    <div class="home_title">
        <p>{{first_types_list[0] && first_types_list[0].name}}</p>
        <p @click="morecourse(2)">更多<img src="@/assets/right_arrow.png"></p>
    </div>
    <div class="view_box">
        <el-tabs v-model="activeName_one" @tab-click="handleClick_one">
            <el-tab-pane label="推荐" name="0" >
                <div class="home_msg" v-if="classRecommendList_one.length>0">
                    <div class="swiper-container" id="a3">
                        <div class="swiper-wrapper" ref="swiper">
                            <div class="swiper-slide" v-for="(item,index) in classRecommendList_one" @click="todetail(item)" :key="index">
                                <div class="home_msg_right">
                                    <img :src="item.coverPath">
                                    <div class="home_msg_right_title">{{item.packageName}}</div>
                                    <div v-if="item.isFree != 0">
                                        <p v-if="item.discountPrice && Number(item.discountPrice) >= 0">
                                            <span class="color_red">¥{{item.discountPrice}}</span>
                                            <span class="color_ccc">¥{{item.price}}</span>
                                        </p>
                                    </div>
                                    <div v-if="item.isFree == 0">
                                        <span class="color_blue">免费</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="inl btnl swiper-button-prev" id="cc_right"></div>
                    <div class="inl btnr swiper-button-next" id="cc_left"></div>
                </div>
            </el-tab-pane>
            <!-- <el-tab-pane :label="item.name"  v-for="(item,index) in second_types_one" :key="index" :name="JSON.stringify(item.id)">
                <div class="home_msg" v-if="second_one_classList.length>0">
                    <div class="swiper-container" id="a3" >
                        <div class="swiper-wrapper" ref="swiper">
                            <div class="swiper-slide" v-for="(classItem,key) in second_one_classList" @click="todetail(classItem)" :key="key">
                                <div class="home_msg_right">
                                    <img :src="classItem.coverPath">
                                    <div class="home_msg_right_title">{{classItem.packageName}}</div>
                                    <div v-if="classItem.isFree != 0 && classItem.discountPrice && Number(classItem.discountPrice) > 0">                                        
                                        <span class="color_red">¥{{classItem.discountPrice}}</span>
                                        <span class="color_ccc">¥{{classItem.price}}</span>
                                    </div>
                                    <div v-if="classItem.isFree != 0 && (!classItem.discountPrice || Number(classItem.discountPrice) == 0)">                                        
                                        <span class="color_red">¥{{classItem.price}}</span>
                                    </div>
                                    <div v-if="classItem.isFree == 0">
                                        <span class="color_blue">免费</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="inl btnl swiper-button-prev" id="cc_right" v-if="second_one_classList.length>0"></div>
                    <div class="inl btnr swiper-button-next" id="cc_left" v-if="second_one_classList.length>0"></div>
                    <div v-if="second_one_classList.length==0" style="text-align:center;width:100%;">暂无内容</div>
                </div>
            </el-tab-pane> -->
        </el-tabs>
    </div>
    <el-carousel style="width:1200px;margin:0 auto;height:120px" :interval="interval" indicator-position="none" >
        <el-carousel-item v-for="(item,index) in bannerList3" :key="index" style="height:100%;">
            <img style="width:100%;height:100%;cursor:pointer;" :src="item.imgPath" @click="toimgdetail2(item)" >
        </el-carousel-item>
    </el-carousel>
    <!-- <div v-if="transitionList.length > 1">
        <img class="home_advertisement" :src="transitionList[1].picUrl" @click="goHtml(transitionList[1].content,transitionList[1].is_jump,transitionList[1])">
    </div> -->
    <div class="home_title">
        <p>{{first_types_list[1] && first_types_list[1].name}}</p>
        <p @click="morecourse(2)">更多<img src="@/assets/right_arrow.png"></p>
    </div>
    <div class="view_box">
        <el-tabs v-model="activeName_two" @tab-click="handleClick_two">
            <el-tab-pane label="推荐" name="0">
                <div class="home_msg" v-if="classRecommendList_two.length>0">
                    <div class="swiper-container" id="a3">
                        <div class="swiper-wrapper" ref="swiper">
                            <div class="swiper-slide" v-for="(item,index) in classRecommendList_two" @click="todetail(item)" :key="index">
                                <div class="home_msg_right">
                                    <img :src="item.coverPath">
                                    <div class="home_msg_right_title">{{item.packageName}}</div>
                                    <div v-if="item.isFree != 0 && item.discountPrice && Number(item.discountPrice) > 0">
                                        <span class="color_red">¥{{item.discountPrice}}</span>
                                        <span class="color_ccc">¥{{item.price}}</span>
                                    </div>
                                    <div v-if="item.isFree != 0 && (!item.discountPrice || Number(item.discountPrice) == 0)">
                                        <span class="color_red">¥{{item.price}}</span>
                                    </div>
                                    <div v-if="item.isFree == 0">
                                        <span class="color_blue">免费</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="inl btnl swiper-button-prev" id="cc_right"></div>
                    <div class="inl btnr swiper-button-next" id="cc_left"></div>
                </div>
            </el-tab-pane>
            <!-- <el-tab-pane :label="item.name"  v-for="(item,index) in second_types_two" :key="index" :name="JSON.stringify(item.id)">
                <div class="home_msg" v-if="second_two_classList.length>0">
                    <div class="swiper-container" id="a4">
                        <div class="swiper-wrapper" ref="swiper">
                            <div class="swiper-slide" v-for="(item,index) in second_two_classList" @click="todetail(item)" :key="index">
                                <div class="home_msg_right">
                                    <img :src="item.coverPath">
                                    <div class="home_msg_right_title">{{item.packageName}}</div>
                                    <div v-if="item.isFree != 0 && item.discountPrice && Number(item.discountPrice) > 0">
                                        <span class="color_red">¥{{item.discountPrice}}</span>
                                        <span class="color_ccc">¥{{item.price}}</span>
                                    </div>
                                    <div v-if="item.isFree != 0 && (!item.discountPrice || Number(item.discountPrice) == 0)">
                                        <span class="color_red">¥{{item.price}}</span>
                                    </div>
                                    <div v-if="item.isFree == 0">
                                        <span class="color_blue">免费</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="inl btnl swiper-button-prev" id="dd_right"></div>
                    <div class="inl btnr swiper-button-next" id="dd_left"></div>
                </div>
            </el-tab-pane> -->
        </el-tabs>
    </div>
    
    <div class="home_title">
        <p>{{first_types_list[2] && first_types_list[2].name}}</p>
        <p @click="morecourse(2)">更多<img src="@/assets/right_arrow.png"></p>
    </div>
    <div class="view_box">
        <el-tabs v-model="activeName_three" @tab-click="handleClick_three">
            <el-tab-pane label="推荐" name="0">
                <div class="home_msg" v-if="classRecommendList_three.length>0">
                    <div class="swiper-container" id="a3">
                        <div class="swiper-wrapper" ref="swiper">
                            <div class="swiper-slide" v-for="(item,index) in classRecommendList_three" @click="todetail(item)" :key="index">
                                <div class="home_msg_right">
                                    <img :src="item.coverPath">
                                    <div class="home_msg_right_title">{{item.packageName}}</div>
                                    <div v-if="item.isFree != 0 && item.discountPrice && Number(item.discountPrice) > 0">
                                        <span class="color_red">¥{{item.discountPrice}}</span>
                                        <span class="color_ccc">¥{{item.price}}</span>
                                    </div>
                                    <div v-if="item.isFree != 0 && (!item.discountPrice || Number(item.discountPrice) == 0)">
                                        <span class="color_red">¥{{item.price}}</span>
                                    </div>
                                    <div v-if="item.isFree == 0">
                                        <span class="color_blue">免费</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="inl btnl swiper-button-prev" id="cc_right"></div>
                    <div class="inl btnr swiper-button-next" id="cc_left"></div>
                </div>
            </el-tab-pane>
            <!-- <el-tab-pane :label="item.name"  v-for="(item,index) in second_types_three" :key="index" :name="JSON.stringify(item.id)">
                <div class="home_msg" v-if="second_three_classList.length>0">
                    <div class="swiper-container" id="a5">
                        <div class="swiper-wrapper" ref="swiper">
                            <div class="swiper-slide" v-for="(item,index) in second_three_classList" @click="todetail(item)" :key="index">
                                <div class="home_msg_right">
                                    <img :src="item.coverPath">
                                    <div class="home_msg_right_title">{{item.packageName}}</div>
                                    <div v-if="item.isFree != 0 && item.discountPrice && Number(item.discountPrice) > 0">
                                        <span class="color_red">¥{{item.discountPrice}}</span>
                                        <span class="color_ccc">¥{{item.price}}</span>
                                    </div>
                                    <div v-if="item.isFree != 0 && (!item.discountPrice || Number(item.discountPrice) == 0)">
                                        <span class="color_red">¥{{item.price}}</span>
                                    </div>
                                    <div v-if="item.isFree == 0">
                                        <span class="color_blue">免费</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="inl btnl swiper-button-prev" id="ee_right"></div>
                    <div class="inl btnr swiper-button-next" id="ee_left"></div>
                </div>
            </el-tab-pane> -->
        </el-tabs>
    </div>
    
    <!-- <div v-if="transitionList.length > 2">
        <img class="home_advertisement" :src="transitionList[2].picUrl" @click="goHtml(transitionList[2].content,transitionList[2].is_jump)">
    </div> -->

    <el-carousel style="width:1200px;margin:0 auto;height:120px" :interval="interval" indicator-position="none" >
            <el-carousel-item v-for="(item,index) in bannerList2" :key="index" style="height:100%;">
                <img style="width:100%;height:100%;cursor:pointer;" :src="item.imgPath" @click="toimgdetail2(item)" >
            </el-carousel-item>
        </el-carousel>


    <!-- <div class="home_title">
        <p>名师答疑</p>
        <p @click="askMore()">更多<img src="@/assets/right_arrow.png"></p>
    </div>
    <div class="home_msg">
        <div class="home_msg_answer">
            <div class="home_msg_answer_item" v-for="(item,index) in recommendList" :key="index" @click="goAsk(item)">
                <div class="home_msg_answer_item_top">
                    <p class="home_msg_answer_item_top_tips" v-if="item.type==1">热门</p>
                    <p class="home_msg_answer_item_top_tips" v-if="item.type==2">推荐</p>
                    <p class="home_msg_answer_item_top_tips" v-if="item.type==3">普通</p>
                    <p class="home_msg_answer_item_top_tips" style="background:rgba(234, 238, 255, 1);color:rgba(113, 136, 255, 1);" v-if="item.type=='' || item.type==null || item.type==undefined">问答</p>
                    <p class="home_msg_answer_item_top_question longtext_one2">{{item.questionName}}</p>
                </div>
                <div class="home_msg_answer_item_bottom">
                    <p class="home_msg_answer_item_bottom_title">回答:</p>
                    <p class="home_msg_answer_item_bottom_answer long_text_3">{{item.answerContent}}</p>
                </div>
            </div>
            
        </div>
    </div> -->
    
    
    <!-- <div class="home_teacher">
        <div class="home_title">
            <p>金牌名师</p>
            <p @click="torealtimeinfo()">更多<img src="@/assets/right_arrow.png"></p>
        </div>
        <div class="home_teacher_list">
            <div class="home_teacher_list_item" v-for="(item,index) in teacherList" :key="index" @click="goTeacherDetail(item)">
                <img :src="item.imagePath" alt="">
                <div class="home_teacher_list_item_name">{{item.expertName}}</div>
                <div class="home_teacher_list_item_hover">
                    <p>{{item.expertName}}</p>
                    <p>{{item.expertiseAreaName}}</p>
                    <p>{{item.introduction}}</p>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="home_material">
        <div class="home_material_box">
            <div class="home_material_box_left">
                <div class="home_material_box_left_title">海量资料免费下载</div>
                <div class="home_material_box_left_text">
                    <p>学习资料下载</p>
                    <p>海量会计资料，全部免费下载学习</p>
                    <p>月下载量10000+</p>
                </div>
            </div>
            <div class="home_material_box_right">
                <div class="home_material_box_right_img">
                    <img src="@/assets/home/experience_img.png" alt="">
                </div>
                <button @click="goDatum()">立即体验</button>
            </div>
        </div>
    </div> -->
    <!-- <div class="home_news">
        <div class="home_news_box">
            <div class="home_news_box_left">
                <div class="home_news_box_left_tips"></div>
                <div class="home_news_box_left_content">
                    <p>会计资讯</p>
                    <ul>
                        <li v-for="(item,index) in industryList" :key="index" @click="goInformationDetail(item)">
                            <span class="longtext_one">{{item.informationTitle}}</span>
                            <span>{{item.intime_new}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="home_news_box_right">
                <p>会计资讯</p>
                <p>会计人想要的这里都有</p>
                <p>名家专栏、课程笔记、财税工具模版、政策法规</p>
                <button @click="goInformation()">立即体验</button>
            </div>
        </div>
    </div> -->
    <div class="home_position">
        <div class="home_position_top">
            <p @click="goService()">
                <img src="@/assets/home/service.png" alt="">
            </p>
            <p>
                <el-popover placement="left" width="120" trigger="hover">
                    <img :src="website.qrCodeSidebar" alt="" style="width:120px;height:120px;">
                    <el-button slot="reference">
                        <img src="@/assets/home/WX_icon.png" alt="">
                    </el-button>
                </el-popover>
            </p>
            <p>
                <el-popover placement="left" width="120" trigger="hover">
                    <span>{{website.telephone}}</span>
                    <el-button slot="reference">
                        <img src="@/assets/home/mobile_icon.png" alt="">
                    </el-button>
                </el-popover>
                
            </p>
        </div>
        <div class="home_position_bottom">
            <p>
               <el-popover placement="left" width="120" trigger="hover">
                    <img :src="website.qrCodeApp" alt="" style="width:120px;height:120px;">
                    <el-button slot="reference">
                        <img src="@/assets/home/APP_icon.png" alt="">
                    </el-button>
                </el-popover>
                <!-- <img src="@/assets/home/APP_icon.png" alt=""> -->
            </p>
            <p @click="toTop">
                <img src="@/assets/home/back_top.png" alt="">
            </p>
        </div>
    </div>
    <t_footer></t_footer>
    <!-- <popup_login :todos="todos" :isType="isType"  @setTodos="getTodos"></popup_login> -->
  </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue';
import t_footer from '@/components/t_footer/index.vue'
// import popup_login from '@/components/popup_login/index.vue'
import Swiper from "swiper"
import { banner_list3,banner_list2,banner_list,freelive_list,first_types,second_types,course_list,information_list,recommend_list,teacher_list,module_list,classRecommend_list,advertise_list1,advertise_list2 } from "@/api/home";
import { wangzhanpeizhi } from "@/api/login";
export default {
  name: 'home',
  components: {
    t_header,
    t_footer,
    // popup_login
  },
  data(){
    return{
        name:'',
        bannerList:[],
        bannerList3:[],
        // 免费直播
        freeLiveList:[],
        //免费课程
        freeCourseList:[],

        teacherList:[],//名师列表
        
        industryList:[],//资讯
        
        transitionList:[],//广告过渡
        recommendList:[],
        classRecommendList_one:[],
        classRecommendList_two:[],
        classRecommendList_three:[],
        // isSort:true,//是否展示课程分类
        isBannerIcon:'hover',//是否显示轮播icon
        interval:5000,
        // todos:false,
        // isType:1,
        first_types_list:[],
        second_types_one:[],
        second_types_two:[],
        second_types_three:[],

        second_one_classList:[],
        second_two_classList:[],
        second_three_classList:[],
        activeName_one:'0',
        activeName_two:'0',
        activeName_three:'0',
        moduleList:[],//小模块
        isShowModule:false,
        isUserInfo:false,
        bannerList2:[],
        banner_list3:[],
        website:{},
    }
  },
  created(){
    if (window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
    ) {
      let mobileurl = 'https://m.saen.com'
      window.location.replace(mobileurl);
    } 
  },
  mounted(){
      // console.log("首页mounted");
   
    this.getBannerList()
    this.freelive_list()
    this.freecourse_list()
    this.first_types()
    this.information_list()
    this.teacher_list()
    this.recommend_list()
    this.module_list()
    this.advertise_list1()
    this.advertise_list2()
    setTimeout(() => {
        this._swiper();
    }, 1000);
    window.addEventListener("scroll", this.handleScroll, true);
    // this.getTodos()
    this.getBannerList2();
    this.getBannerList3();
    this.wangzhanpeizhi();
  },
  methods:{
    checkLogin(){
        const userInfo = localStorage.getItem("setuserInfo");
        if(userInfo==null || userInfo=='null'){
            this.isUserInfo=true;
            setTimeout(() => {
                this.isUserInfo=false;
            }, 0);
            return false
        }else{
            return userInfo
        }
    },
    wangzhanpeizhi(){
        wangzhanpeizhi().then(res=>{
            // console.log("网站配置");
            // console.log(res);
            if(res.data.code == 0){
                this.website = res.data.data;
                if(res.data.data.title){
                    document.title = res.data.data.title;
                }
            }
        }).catch(error=>{
            console.log(error);
        })
    },
    //答疑更多
    askMore(){
        let routeUrl =  this.$router.resolve({
            path:'/ask',
            query:{}
        })
        window.open(routeUrl.href, '_blank');
    },
    more_zhibo(){
        let routeUrl =this.$router.resolve({
            path:'/liveList',
            query:{}
        })
        window.open(routeUrl.href, '_blank');
    },
    //   广告1
    advertise_list1(){
        advertise_list1().then(res=>{
            console.log(res)
        }).catch(error=>{
            console.log(error)
        })
    },
    //   广告2
    advertise_list2(){
        advertise_list2().then(res=>{
            console.log(res)
        }).catch(error=>{
            console.log(error)
        })
    },
    //  去名师详情
    goTeacherDetail(item){
        let routeUrl = this.$router.resolve({
            path:'/teacherDetail',
            query:{
                teacherObj:JSON.stringify(item)
            }
        })
        window.open(routeUrl.href, '_blank');
    },
    //  去资料
    goDatum(){
        let routeUrl = this.$router.resolve({
            path: '/datum',
            query: {}
        });
        window.open(routeUrl.href, '_blank');
    },
    //  去资讯
    goInformation(){
        let routeUrl =  this.$router.resolve({
            path: '/information',
            query: {}
        });
        window.open(routeUrl.href, '_blank');
    },
    goInformationDetail(item){
        let routeUrl = this.$router.resolve({
            path: '/informationDetail',
            query: {
                informationId:item.informationId
            }
        });
        window.open(routeUrl.href, '_blank');
    },
    //   去客服
    goService(){
        var url = 'https://tb.53kf.com/code/client/10093167/1';
        //var url='https://tb.53kf.com/code/client/28a6552fc10b901b687a66116675d2e07/1'
        // let routeData = this.$router.resolve({ path: url, query: {} });
        //https://tb.53kf.com/code/client/10093167/1 
        window.open(url, '_blank');
    },
    // 去直播间
    toLiveDetail(item){
        // console.log(item)
        // const userInfo = localStorage.getItem("setuserInfo");
        // console.log(userInfo);
        // if(userInfo==null){
        //     this.isUserInfo=true
        //     return false
        // }
        const userInfo = this.checkLogin();
        if(!userInfo){
            return false
        }

        // var url='https://live.polyv.cn/watch/'+item.channelId
        // window.open(url, '_blank');
        // let routeUrl = this.$router.resolve({
        //     path: '/liveVideo',
        //     query: {
        //         channelId:item.channelId
        //     }
        // });
        // window.open(routeUrl.href, '_blank');
        if(item.type == 0){
            let routeUrl = this.$router.resolve({ //liveVideo
                    path: '/videoPlayRoom',
                    query: {
                        channelId: item.channelId,
                        title: item.name,
                        id: item.id,
                        liveType: 1,
                        state:"1",
                    }
            });
            window.open(routeUrl.href, '_blank');

        }
        

    },
    // 切换tab
    handleClick_one(tab,event){
        if(tab.index==0){
            this.activeName_one='0'
            var obj={
                firstTypeId:this.first_types_list[0].id,
                secondTypeId:'',
            }
            this.classRecommend_list(obj)
        }else{
            this.activeName_one=tab.name
            var obj={
                firstTypeId:this.first_types_list[0].id,
                secondTypeId:this.second_types_one[tab.index-1].id,
            }
            this.course_list(obj,1)
        }
    },
    // getClass one

    handleClick_two(tab,event){
        // console.log('tab',tab)
        // console.log('event',event)
        if(tab.index==0){
            this.activeName_two='0'
            this.classRecommend_list()
        }else{
            this.activeName_two=tab.name
            var obj={
                firstTypeId:this.first_types_list[1].id,
                secondTypeId:this.second_types_two[tab.index-1].id,
            }
            this.course_list(obj,2)
        }
    },
    handleClick_three(tab,event){
        // console.log('tab',tab)
        // console.log('event',event)
        if(tab.index==0){
            this.activeName_three='0'
            this.classRecommend_list()
        }else{
            this.activeName_three=tab.name
            var obj={
                firstTypeId:this.first_types_list[2].id,
                secondTypeId:this.second_types_three[tab.index-1].id,
            }
            this.course_list(obj,3)
        }
    },
    // 推荐课程
    classRecommend_list(obj,i){
        // console.log('obj',obj)
        course_list(obj).then(res=>{
            // console.log(res)
            if(res.data.code==0){
                if(i==0){
                    this.classRecommendList_one=res.data.data
                }else if(i==1){
                    this.classRecommendList_two=res.data.data
                }else if(i==2){
                    this.classRecommendList_three=res.data.data                    
                }
            }else{
                this.$message.error(res.data.msg);
            }
        }).catch(error=>{
            console.log(error)
        })
    },
    // 父传子
    getTodos(e){
        // console.log(e)
        this.isUserInfo=e
    },
    // 接收头部组件传值
    // getTodos(e){
    //     console.log(e)
    //     var obj=JSON.parse(e)
    //     this.todos = obj.todos
    //     this.isType=obj.num
    // },
    // 获取banner图
    getBannerList(){
        banner_list().then(res=>{
            if(res.data.code == 0){
                this.bannerList = res.data.data
            }
            else{
                this.$message.error(res.data.msg);
            }
        }).catch(error=>{
            console.log(error)
        })
    },
    getBannerList2(){
        banner_list2().then(res=>{
            if(res.data.code == 0){
                this.bannerList2 = res.data.data
            }
            else{
                this.$message.error(res.data.msg);
            }
        }).catch(error=>{
            console.log(error)
        })
    },
    getBannerList3(){
        banner_list3().then(res=>{
            if(res.data.code == 0){
                this.bannerList3 = res.data.data
            }
            else{
                this.$message.error(res.data.msg);
            }
        }).catch(error=>{
            console.log(error)
        })
    },
    // 免费直播列表
    freelive_list(){
        freelive_list().then(res=>{
            // console.log(res)
            if(res.data.code==0){
                this.freeLiveList=res.data.data
            }else{
                this.$message.error(res.data.msg);
            }
        }).catch(error=>{
            console.log(error)
        })
    },

    // 免费课程列表
    freecourse_list(){
        course_list().then(res=>{
            // console.log(res)
            if(res.data.code==0){
                for(var i in res.data.data){
                    if(res.data.data[i].isFree==0){
                        // this.freeCourseList.push(res.data.data[i])
                        this.freeCourseList.unshift(res.data.data[i])
                    }
                }
            }else{
                this.$message.error(res.data.msg);
            }
        }).catch(error=>{
            console.log(error)
        })
    },
    // 课程列表
    course_list(obj,num){
        course_list(obj).then(res=>{
            // console.log(res)
            // console.log(num)
            if(res.data.code==0){
                if(num==1){
                    this.second_one_classList=res.data.data
                }else if(num==2){
                    this.second_two_classList=res.data.data
                }else if(num==3){
                    this.second_three_classList=res.data.data
                }
                // this.freeCourseList=res.data.data
            }else{
                this.$message.error(res.data.msg);
            }
        }).catch(error=>{
            console.log(error)
        })
    },
    // 课程所有一级分类
    first_types(){
        first_types().then(res=>{
            console.log(res)
            if(res.data.code==0){
                this.first_types_list=res.data.data
                this.$store.commit("setfirstTypesList", {firstTypesList:res.data.data});
                for(var i in res.data.data){
                    if(i>=3){
                        return false
                    }else{
                        this.second_types(res.data.data[i].id,i)
                        var obj={
                            firstTypeId:res.data.data[i].id,
                            secondTypeId:'',
                        }
                        this.classRecommend_list(obj,i)
                    }
                    
                    console.log(i)
                }
                
            }else{
                this.$message.error(res.data.msg);
            }
        }).catch(error=>{
            console.log(error)
        })
    },
    // 课程所有二级分类
    second_types(num,index){
        var obj={
            firstTypeId:num
        }
        second_types(obj).then(res=>{
            // console.log(res)
            if(res.data.code==0){
                if(index==0){
                    this.second_types_one=res.data.data
                }else if(index==1){
                    this.second_types_two=res.data.data
                }else if(index==2){
                    this.second_types_three=res.data.data
                }
            }
        }).catch(error=>{
            console.log(error)
        })
    },
    // 名师列表
    teacher_list(){
        teacher_list().then(res=>{
            // console.log(res)
            if(res.data.code==0){
                for(var i in res.data.data){
                    if(i>=4){
                        return false
                    }else{
                        res.data.data[i].expertiseAreaName=res.data.data[i].expertiseAreaNameList.join(',')
                        this.teacherList.push(res.data.data[i])
                    }
                }
            }else{

            }
        }).catch(error=>{
            console.log(error)
        })
    },
    // 去答疑详情
    goAsk(item){
        // console.log(item)
        let routeUrl = this.$router.resolve({
            path: '/askDetail',
            query: {
                wdId:item.wdId
            }
        });
        window.open(routeUrl.href, '_blank');
    },
    // 名师答疑推荐列表
    recommend_list(){
        recommend_list().then(res=>{
            // console.log(res)
            if(res.data.code==0){
                var results = [];
                var arr = res.data.data;
                for(var i = 0;i<arr.length;i++){
                    if(i<=3){
                        results.push(arr[i]);
                    }
                }
                
                this.recommendList=results
            }
        }).catch(error=>{
            console.log(error)
        })
    },
    // 小模块
    module_list(){
        module_list().then(res=>{
            // console.log(res)
            if(res.data.code==0){
                // console.log("---------------------------")
                var list = res.data.data;
                var rList = [];
                var obj = {};
                for(var i = 0;i<list.length;i++){
                    if(i ==1){
                        obj = list[i];
                    }else{
                        rList.push(list[i]);
                    }
                }
                rList.push(obj);
                this.moduleList=rList;
                if(this.moduleList.length>0){
                    this.isShowModule=true
                }else{
                    this.isShowModule=false
                }
            }
        }).catch(error=>{
            console.log(error)
        })
    },
    // 跳转更多页面
    morecourse(num,value){
       let routeUrl =  this.$router.resolve({
            path: '/course',
            query: {}
        });
        window.open(routeUrl.href, '_blank');
    },
    // 去资讯
    torealtimeinfo(){
        let routeUrl = this.$router.resolve({
            path: '/teacherList',
            query: {}
        });
        // console.log("url:"+routeUrl.href);
        window.open(routeUrl.href, '_blank');
        
    },
    // 资讯列表
    information_list(){
        information_list().then(res=>{
            // console.log(res)
            if(res.data.code==0){
                var industryList=[]
                for(var i in res.data.data){
                    if(i <=5){
                        if(res.data.data[i].intime.length>10){
                            res.data.data[i].intime_new=res.data.data[i].intime.slice(0,10)
                            this.industryList.push(res.data.data[i])
                        }
                    }
                    
                }
            }
        }).catch(error=>{
            console.log(error)
        })
    },
    // 去资讯详情
    tonewdetail(item){
        let routeUrl =this.$router.resolve({
            path: '/realtimeinfodetail',
            query: {industryId:item.industryId}
        });
        window.open(routeUrl.href, '_blank');
    },
    // 去课程详情
    todetail(item){
        let routeUrl =this.$router.resolve({
            path: '/coursedetail',
            query: {coursePackageId:item.coursePackageId,isFree:item.isFree}
        });
        window.open(routeUrl.href, '_blank');
    },
    // 小模板跳转
    goskip(e){
        console.log(e)
        var url ='';
        if(e.linkUrl.indexOf('http') != -1){
            url = e.linkUrl;
        }else{
            url = "http://"+e.linkUrl;
        }
        window.open(url, '_blank');
    },
    // 去banner详情
    toimgdetail(item){
        if(item.type == 2) {
            return false
        }
        else if(item.type == 1){
            // this.$router.push({
            //     path: '/html',
            //     query: {type:1,bannerId:item.banId}
            // });
            window.open(item.linkUrl, '_blank');
        }
    },
    toimgdetail2(item){
        window.open(item.linkUrl, '_blank');
    },
    // 返回顶部
    handleScroll() {
        let scrolltop =
            document.documentElement.scrolltop || document.body.scrollTop;
        scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
        let top = document.documentElement.scrollTop || document.body.scrollTop;
        //实现滚动效果
        const timeTop = setInterval(() => {
            document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
            if (top <= 0) {
            clearInterval(timeTop);
            }
        }, 10);
    },
    // 配置
    _swiper(){
        var effect = 0; 
        
        var swiper1 = new Swiper('#a1', {
            loop: false,
            speed: 1000,
            slidesPerView:4,
            centeredSlides:false,
            autoplay:false,
            observer:true,
            observeParents:true,
            spaceBetween: 15,
            watchSlidesProgress : true,
            preventLinksPropagation:false,
            
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '#aa_left',
                prevEl: '#aa_right',
            },
        });
        var swiper2 = new Swiper('#a2', {
            loop: false,
            speed: 1000,
            slidesPerView:4,
            centeredSlides:false,
            autoplay:false,
            observer:true,
            observeParents:true,
            spaceBetween: 15,
            watchSlidesProgress : true,
            preventLinksPropagation:false,
            
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '#bb_left',
                prevEl: '#bb_right',
            },
        });
        var swiper3 = new Swiper('#a3', {
            loop: false,
            speed: 1000,
            slidesPerView:4,
            centeredSlides:false,
            autoplay:false,
            observer:true,
            observeParents:true,
            spaceBetween: 15,
            watchSlidesProgress : true,
            preventLinksPropagation:false,
            
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '#cc_left',
                prevEl: '#cc_right',
            },
        });
        var swiper4 = new Swiper('#a4', {
            loop: false,
            speed: 1000,
            slidesPerView:4,
            centeredSlides:false,
            autoplay:false,
            observer:true,
            observeParents:true,
            spaceBetween: 15,
            watchSlidesProgress : true,
            preventLinksPropagation:false,
            
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '#dd_left',
                prevEl: '#dd_right',
            },
        });
        var swiper5 = new Swiper('#a5', {
            loop: false,
            speed: 1000,
            slidesPerView:4,
            centeredSlides:false,
            autoplay:false,
            observer:true,
            observeParents:true,
            spaceBetween: 15,
            watchSlidesProgress : true,
            preventLinksPropagation:false,
            
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '#ee_left',
                prevEl: '#ee_right',
            },
        });
    },
  }
}
</script>

<style >
    .longtext_one {
        width: 80%; 
        overflow:hidden; 
        white-space: nowrap;
        text-overflow:ellipsis;
        text-align:left;
    }
     .longtext_one2 {
        width: 100%; 
        overflow:hidden; 
        white-space: nowrap;
        text-overflow:ellipsis;
        text-align:left;
    }
    .long_text_3{
        display:-webkit-box;/**对象作为伸缩盒子模型展示**/
        -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
         overflow:hidden;/**隐藏超出的内容**/
         -webkit-line-clamp:3;/**显示的行数**/
    }
    .el-carousel__container{
        height:120px;
    }

</style>
