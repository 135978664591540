import $axios from './index'

//首页banner
export function banner_list(data){
	const url = '/api/pc/v1/index/banner/list';
	return $axios.post(url,data);
}
//免费直播列表
export function freelive_list(data){
	const url = '/api/pc/v1/index/free/live/list';
	return $axios.post(url,data);
}
//课程所有一级分类
export function first_types(data){
	const url = '/api/app/v1/course/type/first/types';
	return $axios.post(url,data);
}
//课程二级分类
export function second_types(data){
	const url = '/api/app/v1/course/type/second/types';
	return $axios.post(url,data);
}
//套餐列表
export function course_list(data){
	const url = '/api/pc/v1/index/course/package/list';
	return $axios.post(url,data);
}
//资讯列表
export function information_list(data){
	const url = '/api/pc/v1/index/information/list';
	return $axios.post(url,data);
}
//资讯列表-资讯栏目
export function information_list2(data){
	const url = '/api/pc/v1/information/list';
	return $axios.post(url,data);
}
//专家列表
export function teacher_list(data){
	const url = 'api/pc/v1/index/teacher/list';
	return $axios.post(url,data);
}
//名师答疑推荐列表
export function recommend_list(data){
	const url = '/api/pc/v1/index/wd/recommend/list';
	return $axios.post(url,data);
}
//广告列表1
export function advertise_list1(data){
	const url = '/api/pc/v1/index/advertise/list1';
	return $axios.post(url,data);
}
//广告列表2
export function advertise_list2(data){
	const url = '/api/pc/v1/index/advertise/list2';
	return $axios.post(url,data);
}
//小模块列表
export function module_list(data){
	const url = '/api/pc/v1/index/module/list';
	return $axios.post(url,data);
}
//推荐套餐列表
export function classRecommend_list(data){
	const url = '/api/pc/v1/course/package/recommend/list';
	return $axios.post(url,data);
}

//首页banner
export function banner_list2(data){
	const url = '/api/pc/v1/index/advertise/list2';
	return $axios.post(url,data);
}
export function banner_list3(data){
	const url = '/api/pc/v1/index/advertise/list1';
	return $axios.post(url,data);
}
